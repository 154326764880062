.container {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
}

/* All Titles ------------------------------------------------------------------------------------------------------------------------*/

.Main-heading {
  max-width: 770px;
  color: #111;
  text-align: center;
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;
  margin: 0;
  margin-bottom: 12px;
}

.preview-title {
  color: #111;
  text-align: center;
  font-size: 46px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.feature-title {
  color: #111;
  text-align: center;
  font-size: 46px;
  font-weight: 700;
  line-height: 72px;
  margin-top: 0;
}

.AdTag-heading {
  max-width: 770px;
  text-align: left;
  color: #111;
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;
  margin: 0;
  margin-bottom: 20px;
}

.Genuin-title {
  color: #111;
  text-align: center;
  font-size: 46px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 20px;
}

.SocialApps-title {
  color: #111;
  font-size: 46px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 12px;
}

.Dashboard-title {
  max-width: 950px;
  color: #111;
  text-align: center;
  font-size: 46px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.Result-title {
  color: #111;
  /* text-align: center; */
  font-size: 46px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.Brands-title {
  color: #111;
  text-align: center;
  font-size: 42px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .Main-heading {
    color: #111;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    margin-bottom: 12px;
  }

  .adreel-title {
    color: #111;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
  }

  .preview-title {
    color: #111;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .Result-title {
    color: #111;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.2px;
    margin: 0;
  }

  .Dashboard-title {
    color: #111;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.2px;
    margin: 0;
  }

  .SocialApps-title {
    color: #111;
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .Genuin-title {
    color: #111;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .AdTag-heading {
    color: #111;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    /* margin-bottom: 20px; */
  }

  .feature-title {
    color: #111;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
  }
}

/* All Descriptions --------------------------------------------------------------------------------------------------------- */

.Home-description {
  max-width: 670px;
  color: #353535;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
}

.AdTags-description {
  color: #353535;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1.2px;
  margin: 0;
  margin-bottom: 20px;
}

.preview-description {
  text-align: center;
  color: #353535;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.6px;
  margin: 0;
  margin-bottom: 40px;
}

.Genuin-description {
  color: #353535;
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.8px;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}

.SocialApps-description {
  color: #353535;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.8px;
  line-height: 38px;
}

.Dashboard-description {
  color: #353535;
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.8px;
  text-align: center;
}

.Result-description {
  color: #353535;
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.8px;
  /* text-align: center; */
}

@media (max-width: 768px) {
  .Home-description {
    color: #353535;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
  }

  .Result-description {
    color: #353535;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.56px;
    text-align: center;
  }

  .preview-description {
    color: #353535;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.48px;
    text-align: center;
  }

  .Dashboard-description {
    color: #353535;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.56px;
    text-align: center;
    margin-bottom: 20px;
  }

  .SocialApps-description {
    color: #353535;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.48px;
    line-height: 26px;
    margin: 0;
  }

  .Genuin-description {
    color: #353535;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.48px;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
  }

  .AdTags-description {
    text-align: center;
    color: #353535;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.56px;
    margin: 0;
    margin-bottom: 20px;
  }
}

/* NavigationBar -----------------------------------------------------------------------------------------------------*/

.sticky-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.scrollable-content {
  padding-top: 70px;
}

.navbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
}

.navbar-logo {
  display: flex;
}

.navbar-logo img {
  max-height: 42px;
}

.navbar-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-button {
  flex-shrink: 0;
  background-color: #0645ff;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 768px) {
  .navbar-logo img {
    max-height: 34px;
  }

  .navbar-button {
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

/* Home.css -----------------------------------------------------------------------------------------------------*/
.AdReel-button {
  background-color: #0645ff;
  color: #fff;
  text-align: center;
  border: none;
  margin-left: 20px;
  padding: 16px 36px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.genuin-title-2 {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1.12px;
  background-color: #fdfcf8;
  margin: 0;
  padding: 30px;
}

.AdReel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.AdReel-mockup-box {
  position: relative;
  height: 100%;
  width: 50%;
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
}

.AdReel-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  width: 400px;
  position: absolute;
}

.AdReel-text-box {
  height: 100%;
  width: 50%;
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 700px;
}

.AdReel-text-comp {
  width: 90%;
  margin-bottom: 20px;
}

.AdReel-title {
  color: #111;
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;
}

.AdReel-description {
  color: #111;
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1.2px;
}

.genuin-mockup-img {
  height: 600px;
}

.AdReel-mockup-video {
  height: 600px;
  width: 300px;
  z-index: 2;
  border-radius: 45px;
}

@media (max-width: 850px) {
  .AdReel-button {
    padding: 12px 18px;
  }

  .AdReel-container {
    padding-top: 0;
    flex-direction: column-reverse;
  }

  .AdReel-video-container {
    height: 470px;
    width: 200px;
  }

  .AdReel-mockup-video {
    height: 470px;
    /* width: 264px; */
    z-index: 2;
    border-radius: 30px;
  }

  .AdReel-text-comp {
    width: 100%;
  }

  .AdReel-text-box {
    width: 100%;
    height: 400px;
  }

  .AdReel-mockup-box {
    width: 100%;
    height: 500px;
    padding-bottom: 40px;
  }

  .AdReel-title {
    color: #111;
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
  }

  .AdReel-description {
    color: #111;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.48px;
  }
}

/* Preview ---------------------------------------------------------------------------- */

.preview-container {
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
}

.preview-images-container {
  height: 500px;
  /* width: 100%; */
  position: relative;
  display: flex;
  justify-content: center;
}

.preview-image1 {
  position: absolute;
  left: 0;
  height: 450px;
}

.preview-image2 {
  position: absolute;
  height: 450px;
  left: 50%;
  /* border-radius: 35px; */
  transform: translateX(-50%);
}

.preview-image3 {
  position: absolute;
  right: 0;
  height: 450px;
}

.preview-buttons-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.preview-button-text {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
}

.preview-button1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50px;
}

.preview-button2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.preview-button3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 30px;
}

.containerrr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-div,
.right-div,
.center-div {
  /* flex: 1; */
  width: 230px;
}

.left-div {
  text-align: center;
}

.center-div {
  width: 280px;
  text-align: center;
}

.right-div {
  text-align: center;
}

.left-div-2,
.right-div-2,
.center-div-2 {
  flex: 1;
  /* width: 220px; */
}

.left-div-2 {
  text-align: center;
}

.center-div-2 {
  text-align: center;
}

.right-div-2 {
  text-align: center;
}

.dotted-line {
  height: 1px;
  width: 100%;
  background: transparent;
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 5px,
    transparent 5px,
    transparent 10px
  );
}

.vertical-line {
  width: 1px; /* Adjust the thickness of the line */
  height: 100%; /* Make the line stretch the entire height of the container */
  background: transparent;
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 5px,
    transparent 5px,
    transparent 10px
  ); /* Adjust the spacing between the dots by changing the 5px and 10px values */
}

@media (max-width: 768px) {
  .containerrr {
    width: 100%;
    flex-direction: column;
  }

  .left-div,
  .right-div,
  .center-div {
    flex: 1;
    width: 230px;
  }

  .left-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .center-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .right-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .preview-container {
    flex-direction: row-reverse;
  }

  .preview-images-container {
    width: 100%;
    height: 850px;
  }

  .preview-image1 {
    position: absolute;
    top: 0;
    left: unset;
    right: unset;
    height: 250px;
  }

  .preview-image2 {
    position: absolute;
    height: 250px;
    left: unset;
    right: unset;
    top: 50%;
    transform: translateY(-50%);
  }

  .preview-image3 {
    position: absolute;
    bottom: 0;
    right: unset;
    height: 250px;
  }

  .preview-buttons-container {
    flex-direction: column;
    align-items: center;
  }

  .preview-button-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
  }

  .preview-button1 {
    top: 90px;
    bottom: unset;
    left: unset;
    height: fit-content;
  }

  .preview-button2 {
    top: 50%;
    left: unset;
    transform: translateY(-50%);
    height: fit-content;
  }

  .preview-button3 {
    bottom: 90px;
    right: unset;
    height: fit-content;
  }
}

/* NewAdLayouttt ------------------------------------------------------------------------- */
.Ad-button {
  background-color: #0645ff;
  color: #fff;
  border: none;
  padding: 16px 36px;
  cursor: pointer;
  margin: 20px 0px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.AdTag-container {
  padding: 80px 0px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.AdTag-text-1 {
  color: #111;
  font-size: 46px;
  font-weight: 700;
  line-height: normal;
  /* letter-spacing: -1.38px; */
}

.AdTag-text-box {
  text-align: center;
  width: 80%;
}

.AdTag-ad-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.AdTag-device-box {
  display: flex;
  height: 600px;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.AdTag-text-2 {
  color: #111;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.6px;
}

.ad-container {
  width: 60%;
  display: flex;
  justify-content: space-around;
  background-color: #f3f3f3;
  border-radius: 10px;
  margin: 20px;
}

.AdTags-button-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.AdTags-buttom {
  color: #111;
  padding: 10px 16px;
  margin: 6px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 850px) {
  .Ad-button {
    background-color: #0645ff;
    color: #fff;
    border: none;
    padding: 10px 16px;
    cursor: pointer;
    margin: 20px 0px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .ad-container {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
  }

  .AdTags-buttom {
    color: #111;
    padding: 6px 12px;
    margin: 6px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 50px;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .AdTag-text-box {
    width: 100%;
  }

  .AdTag-ad-box {
    width: 100%;
  }

  .AdTag-container {
    flex-direction: column;
    color: #111;
    /* text-align: center; */
    width: 90%;
    height: auto;
    padding: 20px 0px;
  }

  .AdTag-device-box {
    height: 600px;
  }

  .AdTag-text-1 {
    color: #111;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
  }

  .AdTag-text-2 {
    color: #111;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.48px;
  }

  .AdTags-button-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Features.css ---------------------------------------------------------------------------------*/
.slick-slider.slick-initialized {
  .feature-content {
    display: flex !important;
  }
}

.feature-container {
  display: flex;
  flex-direction: column;
  padding: 80px 0px;
  padding-top: 0;
}

.feature-content {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row on smaller screens */
  justify-content: space-evenly;
}

.feature-item {
  flex: 1; /* Equal width for all items */
  max-width: 300px; /* Set a maximum width for each item */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  width: 210px;
}

.feature-image {
  height: 160px;
}

.feature-heading {
  color: #111;
  max-width: 270px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 0;
}

.feature-description {
  color: #353535;
  max-width: 270px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .feature-list {
    display: flex;
    align-items: center;
  }
}

/* GenuinFeed.css ------------------------------------------------------------------------------------------------------*/

.Genuin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px;
}

.Genuin-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.Genuin-button,
.Genuin-button-secondary {
  width: 35%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  padding: 10px 18px 10px 10px;
  cursor: pointer;
  border-radius: 50px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  transition: background-color 0.3s ease;
}

.Genuin-button {
  background-color: #0645ff;
  border: 1px solid #0645ff;
}

.Genuin-button-secondary {
  background-color: transparent;
  color: #0645ff;
}

.Genuin-image-screen {
  max-height: 340px;
  margin: 80px 0;
}

.Genuin-images-container img {
  position: absolute;
  transition: opacity 0.5s ease, transform 0.5s ease; /* Add transition properties for opacity and transform */
}

@media (max-width: 768px) {
  .Genuin-button-container {
    flex-direction: column;
    width: 80%;
  }

  .Genuin-button,
  .Genuin-button-secondary {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    padding: 0px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: start;
    transition: background-color 0.3s ease;
  }
}
/* SocialApps ---------------------------------------------------------------------------------------------------------------- */
.SocialApps-container {
  display: flex;
  align-items: center;
  padding: 0px 0px 80px 0px;
}

.SocialApps-buttons {
  width: 80%;
  display: flex;
  justify-content: space-around;
  background-color: #f3f3f3;
  border-radius: 10px;
  margin: 20px 0px;
}

.SocialApps-text-comp {
  width: 50%;
  display: flex;

  flex-direction: column;
}

.SocialApps-image-comp {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
}

@media (max-width: 768px) {
  .SocialApps-container {
    flex-direction: column-reverse;
  }

  .SocialApps-buttons {
    width: auto;
  }

  .SocialApps-text-comp {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .SocialApps-image-comp {
    width: 100%;
  }
}

/* Dashboard.css -------------------------------------------------------------------------------------------------------------*/

.Dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px 0px 0px;
}

.Dashboard-image-war {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 190px;
}

.Dashboard-image {
  /* height: 450px; */
  width: 60%;
  z-index: 2;
}

.Dashboard-info-box {
  display: flex;
  align-items: center;
  position: unset;
  background-color: #f9f9f9;
  color: #4d4d4d;
  padding: 14px 20px;
  border-radius: 20px;
  border: 1px solid #f9f9f9;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 0px 10px 10px 0px;
}

/* Marquee Container */
.marquee {
  --duration: 40s;
  --gap: 1rem; /* Gap between divs */

  display: flex;
  overflow: hidden;
  user-select: none;
  /* gap: var(--gap); */
  /* transform: skewY(-3deg); */
  max-width: 100vw; /* Set max-width to window width */
}

/* Marquee Image Group ltr*/
.marquee__group_ltr {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  animation: ltr var(--duration) linear infinite;
  white-space: nowrap; /* Prevent content from wrapping */
}

/* Marquee Image Group rtl*/
.marquee__group_rtl {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  animation: rtl var(--duration) linear infinite;
  white-space: nowrap; /* Prevent content from wrapping */
}

@keyframes ltr {
  0% {
    transform: translateX(calc(-100% - var(--gap)));
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes rtl {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@media (max-width: 768px) {
  .Dashboard-image-war {
    margin-top: 20px;
    bottom: 0;
  }

  .Dashboard-image {
    /* height: 450px; */
    width: 90%;
    z-index: 2;
  }

  .Dashboard-info-box {
    display: flex;
    align-items: center;
    position: unset;
    background-color: #f9f9f9;
    color: #4d4d4d;
    padding: 8px 12px;
    border-radius: 10px;
    border: 1px solid #f9f9f9;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    margin: 0px 10px 10px 0px;
  }
}

/* Result --------------------------------------------------------------------------------------------------------------------------- */

.Result-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  padding: 80px 0px;
}

.Result-image {
  height: 350px;
  margin: 30px;
  z-index: 2;
}

.Result-text-comp {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.Result-image-comp {
  width: 50%;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
}

.Result-card-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.96px;
}

.Result-card-styled-text-1 {
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 122%;
  letter-spacing: -2.4px;
  background: linear-gradient(180deg, #0645ff -52.04%, #77ce1a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Result-card-styled-text-2 {
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 122%;
  letter-spacing: -2.4px;
  background: linear-gradient(180deg, #16793d -52.04%, #77ce1a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Result-card-styled-text-3 {
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 122%;
  letter-spacing: -2.4px;
  background: linear-gradient(180deg, #16793d -52.04%, #77ce1a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  .Result-container {
    flex-direction: column;
  }

  .Result-text-comp {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .Result-image-comp {
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 0px;
  }

  .Result-card-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -0.64px;
  }

  .Result-card-styled-text {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 122%;
    letter-spacing: -1.8px;
    background: radial-gradient(
      115.31% 115.31% at 49.78% -15.31%,
      #b3dde2 0%,
      #81b1ea 52.6%,
      #0645ff 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/* Brands --------------------------------------------------------------------------------------------------------------------- */

.Brands-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
}

.Brands-images {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.brand-item {
  height: 36px;
  margin: 20px;
}

.Brands-title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .brand-item {
    max-width: 100%;
    height: 18px;
    margin: 10px;
  }

  .Brands-title {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    text-align: center;
    margin: 10px 0;
  }
}

/* Form -------------------------------------------------------------------------------------------------------------------------------- */

.Form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Form-box-text {
  height: 900px;
  width: 35%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0645ff;
}

.Form-box-image {
  height: 900px;
  width: 65%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(231, 231, 231, 0.4); */
}

.Form-text-comp {
  max-width: 300px;
}

.Form-text-1 {
  color: #fff;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.Form-text-2 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -1.2px;
}

.text-field {
  width: 100%;
  margin-bottom: 15px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  text-align: center;
  width: 100%;
  max-width: 80%;
  position: relative;
}

.select-container {
  width: 100%;
  display: flex;
}

.select-label {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.username-field {
  flex: 3;
}

.Form-submit-button {
  width: 100%;
  background-color: rgba(6, 69, 255, 1);
  color: #fff;
  border: none;
  padding: 8px 48px;
  margin: 8px 0;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  transition: background-color 0.3s ease;
}

.Form-submit-button:hover {
  background-color: rgba(6, 69, 255, 0.8);
}

@media (max-width: 768px) {
  .Form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Form-box-text {
    height: auto;
    padding: 20px 0px;
    width: 100%;
  }

  .Form-box-image {
    height: auto;
    padding: 50px 0px;
    width: 100%;
  }

  .Form-text-comp {
    max-width: 80%;
    text-align: center;
  }

  .Form-text-1 {
    text-align: center;
    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Form-text-2 {
    text-align: center;
    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
  }

  .username-field {
    flex: 5;
  }
}

/* Modal-------------------------------------------------------------------------------------------------------------- */
.Modal-container {
  display: flex;
  justify-content: center;
}

.Modal-text {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Modal-title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  margin: 0;
}

.Modal-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.Modal-select-container {
  width: 100%;
  display: flex;
}

.Modal-select-label {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Modal-username-field {
  flex: 6;
}

.Modal-Form-submit-button {
  width: 100%;
  background-color: #0645ff;
  color: #fff;
  border: none;
  padding: 8px 48px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  transition: background-color 0.3s ease;
}

.Modal-Form-submit-button:hover {
  background-color: #0035a9;
}

body {
  margin: 0;
  overflow-x: hidden !important;
  font-family: 'Avenir Next';
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Regular.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Regular.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Medium.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Medium.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-MediumItalic.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-MediumItalic.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-MediumItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-UltraLight.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-UltraLight.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-UltraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-UltraLightItalic.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-UltraLightItalic.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-UltraLightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-HeavyItalic.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-HeavyItalic.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Italic.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Italic.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-BoldItalic.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-BoldItalic.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-DemiBoldItalic.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-DemiBoldItalic.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Heavy.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Heavy.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-DemiBold.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-DemiBold.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Bold.woff2') format('woff2'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Bold.woff') format('woff'),
    url('https://media.qa.begenuin.com/webapp_assets/fonts/AvenirNext-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}